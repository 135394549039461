<owc-modal-dialog
  [visible]="true"
  disable-backdrop-click="false"
  disable-escape-keydown="false"
  disable-scroll-lock="false"
  dense-content="false"
  (visibleChange)="visibilityChangeHandler($event)"
>
  <div slot="header">
    <ng-container [ngSwitch]="dialogType">
      <span
        i18n="HighLatencyWarningPopup@@applying-filters-may-take-some-time"
        *ngSwitchCase="HIGH_LATENCY_WARNING_TYPE.FILTER"
      >
        Applying filters may take some time
      </span>
      <span
        i18n="HighLatencyWarningPopup@@reclassification-may-take-some-time"
        *ngSwitchCase="HIGH_LATENCY_WARNING_TYPE.RECLASSIFICATION"
      >
        Reclassification may take some time
      </span>
    </ng-container>
  </div>

  <div>
    <p
      i18n="
        HighLatencyWarningPopup@@to-ensure-all-data-displayed-is-up-to-date-there-may-be-a-delay-before-updates-will-be-shown-while-content-is-loading"
    >
      To ensure all data displayed is up to date, there may be a delay before updates will be shown
      while content is loading.
    </p>

    <p i18n="HighLatencyWarningPopup@@delay-may-take-up-to-60-seconds">
      Delay may take up to 60 seconds
    </p>
  </div>

  <div slot="actions" class="space-between">
    <owc-checkbox
      i18n="HighLatencyWarningPopup@@dont-show-next-time"
      (inputChange)="dontShow = $event.detail"
    >
      Don't show next time
    </owc-checkbox>
    <div class="space-between actions">
      <owc-button
        i18n="HighLatencyWarningPopup@@cancel"
        variant="secondary"
        flat
        (click)="actionHandler(false)"
      >
        Cancel
      </owc-button>
      <owc-button i18n="HighLatencyWarningPopup@@acknowledge" flat (click)="actionHandler(true)">
        Acknowledge
      </owc-button>
    </div>
  </div>
</owc-modal-dialog>
