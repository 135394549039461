import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BrowserStorageType } from 'app/model/entities/browser-storage';
import {
  HIGH_LATENCY_WARNING_COOKIE_KEY,
  HIGH_LATENCY_WARNING_TYPE
} from 'app/model/entities/high-latency-types.model';
import { BrowserStorageService } from 'app/services/browser-storage.service';

@Component({
  selector: 'app-high-latency-warning-popup',
  templateUrl: './high-latency-warning-popup.component.html',
  styleUrls: ['./high-latency-warning-popup.component.scss']
})
export class HighLatencyWarningPopupComponent {
  @Input() dialogType: HIGH_LATENCY_WARNING_TYPE;
  @Output() acknowledged: EventEmitter<boolean> = new EventEmitter<boolean>();
  dontShow: boolean = false;
  HIGH_LATENCY_WARNING_TYPE = HIGH_LATENCY_WARNING_TYPE;

  constructor(private browserStorageService: BrowserStorageService) {}

  visibilityChangeHandler(event: CustomEvent): void {
    if (!event.detail) {
      this.acknowledged.emit(false);
    }
  }

  actionHandler(acknowledged: boolean): void {
    if (acknowledged && this.dontShow) {
      this.browserStorageService
        .proxy(BrowserStorageType.COOKIE, HIGH_LATENCY_WARNING_COOKIE_KEY, this.dialogType)
        .write(true);
    }
    this.acknowledged.emit(acknowledged);
  }
}
