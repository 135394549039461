<div
  class="notification-box"
  id="notification-box-{{rowId}}"
  [class.small-size]="smallSize"
>
  <ng-container *ngIf="!hasError; else errorDisplay">{{
    count > COUNT_THRESHOLD ? COUNT_THRESHOLD + '+' : count
  }}</ng-container>
  <ng-template #errorDisplay> - </ng-template>
</div>
<owc-tooltip [attr.anchor]="'notification-box-'+rowId" class="notification-box-{{rowId}}" auto-unportal>
  <app-ruo-ivd-mapper>
    <ng-container i18n="Case activity tooltip for IVD@@case-activity" IVD>
      Case activity
    </ng-container>
    <ng-container i18n="Research case activity tooltip for RUO@@research-case-activity" RUO>
      Research case activity
    </ng-container>
  </app-ruo-ivd-mapper>
</owc-tooltip>
