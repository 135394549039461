<ng-container [ngSwitch]="last.toLowerCase()">
  <span *ngSwitchCase="'amplification'">amplification</span>
  <span *ngSwitchCase="'deletion'">deletion</span>
  <span *ngSwitchCase="'fusion'">fusion</span>
  <span *ngSwitchCase="'high'">High</span>
  <span *ngSwitchCase="'low'">Low</span>
  <span *ngSwitchCase="'intermediate'">Intermediate</span>
  <span *ngSwitchCase="'undetermined'">Undetermined</span>
  <span *ngSwitchCase="'unknown'">Unknown</span>
  <span *ngSwitchCase="'equivocal'">Equivocal</span>
  <span *ngSwitchCase="'stable'">Stable</span>
  <span *ngSwitchDefault [id]="dateNow">{{ last | truncateText: truncateLength }}</span>
  <owc-tooltip [attr.anchor]="dateNow" [class]="dateNow">{{last}}</owc-tooltip>
</ng-container>
