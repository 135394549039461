<div id="chip-pertinent-negative-{{biomarker.id}}" class="chip-pertinent-negative"
     [ngClass]="biomarker.pertinentNegative ? 'chip-pertinent-negative-enabled' : 'chip-pertinent-negative-disabled'">
  <span class="chip-pertinent-negative-title">{{biomarker.name}}</span>
  <ng-container *ngIf="!reportMode && hasPermissionForEditBiomarkerMetadata$ | async">
    <owc-icon name="circle_clear" type="filled" *ngIf="biomarker.pertinentNegative"
      (click)="togglePertinentNegative()"></owc-icon>
    <owc-icon name="circle_add" type="outlined" *ngIf="!biomarker.pertinentNegative"
      (click)="togglePertinentNegative()"></owc-icon>
  </ng-container>
</div>
