import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { VARIANT_FILTER_TYPE } from 'app/model/valueObjects/variantFilter';
import { FilterInputData } from '../variant-filter/variant-filter.component';
import { HIGH_LATENCY_WARNING_TYPE } from 'app/model/entities/high-latency-types.model';

@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent {
  type: string;
  data: {
    filterList: FilterInputData[];
    manualMode: boolean;
    editMode: boolean;
    allowApplyFiltersForTierIAndII: boolean;
    initialIgnoreFiltersForTierIAndII: boolean;
    isDefaultFilter?: boolean;
    canEditVariantFilter: boolean;
    enableWarningDialog?: boolean;
  };
  variantFilterType = VARIANT_FILTER_TYPE;
  showWarningDialog = false;
  newFilters: any;
  HIGH_LATENCY_WARNING_TYPE = HIGH_LATENCY_WARNING_TYPE;

  constructor(private dialogRef: MatDialogRef<FilterPopupComponent>) {}

  closeDialog(result?: any): void {
    this.dialogRef.close(result);
  }

  outputData(filters: any): void {
    this.newFilters = filters;
    if (this.data.enableWarningDialog) {
      this.showWarningDialog = true;
    } else {
      this.closeDialog(this.newFilters);
    }
  }

  acknowledgedHandler(acknowledged): void {
    if (acknowledged) {
      this.closeDialog(this.newFilters);
    } else {
      this.showWarningDialog = false;
    }
  }
}
