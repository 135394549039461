import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CaseService } from './case.service';
import { HttpClient } from '@angular/common/http';
import { mergeMap, finalize, tap } from 'rxjs/operators';
import { DataSharing } from 'app/model/entities/dataSharing';
import { SharedData } from 'app/model/entities/dataSharing';
import { pick as _pick } from 'lodash';
import { SessionLinkKeys } from 'app/model/valueObjects/sessionLinkKeys';

export enum CUSTOM_DATA_SHARING_ICONS {
  greyIcon = 'data-sharing-grey',
  blueIcon = 'data-sharing-colors',
  darkIcon = 'data-sharing'
}

@Injectable()
export class DataSharingService {
  private _optIn = new BehaviorSubject<boolean>(false);
  private _sharedData = new BehaviorSubject<SharedData>(null);
  private _loadingSharedData = new BehaviorSubject<boolean>(false);
  private _sharedDataError = new BehaviorSubject<boolean>(false);
  private _loadingSharing = new BehaviorSubject<boolean>(false);
  private _sharingError = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private caseService: CaseService
  ) {}

  get sharedData(): Observable<SharedData> {
    return this._sharedData.asObservable();
  }

  get optIn(): Observable<boolean> {
    return this._optIn.asObservable();
  }

  get loadingSharedData(): Observable<boolean> {
    return this._loadingSharedData.asObservable();
  }

  get loadingSharing(): Observable<boolean> {
    return this._loadingSharing.asObservable();
  }

  get sharingError(): Observable<boolean> {
    return this._sharingError.asObservable();
  }

  get sharedDataError(): Observable<boolean> {
    return this._sharedDataError.asObservable();
  }

  updateSharing(optIn: boolean) {
    this._loadingSharing.next(true);
    return this.http
      .post<DataSharing>(this.authService.getURL(SessionLinkKeys.CREATE_OR_EDIT_SHARING), {
        optIn
      })
      .pipe(
        finalize(() => this._loadingSharing.next(false)),
        tap((json: DataSharing) => {
          this._optIn.next(json.optIn);
        })
      );
  }

  loadSharing() {
    this._loadingSharing.next(true);
    return this.http
      .get<DataSharing>(this.authService.getURL(SessionLinkKeys.VIEW_SHARING))
      .pipe(finalize(() => this._loadingSharing.next(false)))
      .subscribe({
        next: (json) => {
          this._optIn.next(json.optIn);
          this._sharingError.next(false);
        },
        error: () => this._sharingError.next(true)
      });
  }

  loadSharedData(criteria) {
    this._loadingSharedData.next(true);
    const httpReq = this.http.post<SharedData>(
      this.authService.getURL(SessionLinkKeys.VIEW_DATA_SHARING_ANALYTICS),
      criteria
    );
    criteria.queries.forEach((query) => {
      if (query) {
        query.diagnosis = _pick(query.diagnosis, 'externalId');
        query.variant = _pick(query.variant, ['id', 'type']);
      }
    });
    return httpReq
      .pipe(
        mergeMap((json: any) => this.caseService.renameDiagnosis(json, 'tierClassifications')),
        finalize(() => this._loadingSharedData.next(false))
      )
      .subscribe({
        next: (json) => {
          this._sharedData.next(json);
          this._sharedDataError.next(false);
        },
        error: () => this._sharedDataError.next(true)
      });
  }
}
